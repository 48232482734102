import Head from "next/head"
import React from "react";
import styles from "../styles/Home.module.css";
import Image from "next/image";
import classNames from "classnames";

import logo from "../public/assets/images/logo.png";
import banner from '../public/assets/images/banner.png'
import a1 from '../public/assets/images/a1.png'
import a2 from '../public/assets/images/a2.png'
import a3 from '../public/assets/images/a3.png'
import a4 from '../public/assets/images/a4.png'
import l1 from '../public/assets/images/l1.png'
import l2 from '../public/assets/images/l2.png'
import l3 from '../public/assets/images/l3.png'
import l4 from '../public/assets/images/l4.png'
import l5 from '../public/assets/images/l5.png'
import i1 from '../public/assets/images/i1.png'
import i2 from '../public/assets/images/i2.png'
import i3 from '../public/assets/images/i3.png'
import i4 from '../public/assets/images/i4.png'
import i5 from '../public/assets/images/i5.png'
import Link from "next/link";


export default function Home() {

  return (
    <React.Fragment>
      <Head>
        <title>北京罕萌诊所</title>
        <meta name="robots" content="index,follow" />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="chrome=1" />
        {/* Open Graph 协议 */}
        <meta property="og:locale" content="zh_CN" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="北京罕萌诊所" />
        <meta property="og:url" content="www.hmclinic.cn/" />
        <meta property="og:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/clinic/favicon.png" />
        <meta property="og:image:alt" content="北京罕萌诊所" />
        <meta
          property="og:description"
          content="北京罕萌诊所践行“人民至上，生命至上”的宗旨，秉承医者初心的理想信念而成立经营。
          诊所致力于服务罕见病患者，将依托信息化平台和移动医疗技术，将集-在线诊疗、远程会诊、 线下医疗运营于一体，为罕见病患者提供预防、诊断、治疗、康复等一站式的健康维护和医疗服务，为罕见病患者提供先进的诊疗方案及精准的诊疗服务，创新解决患者用药可及性和获得性，缓解北京及周边区域罕见病诊疗压力，惠及更多的罕见病患者。
          诊所设施齐全，环境温馨，设有内科诊疗科目，并配备药房、治疗室、处置室等辅助科室。地址位于北京市顺义区金航西路4号院2号楼（天竺综合保税区）绿地自由港大厦B栋3层316-319室。"
        />
        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/clinic/favicon.png" />
        <meta property="og:width" content="64" />
        <meta property="og:height" content="64" />
        <meta property="og:image:width" content="64" />
        <meta property="og:image:height" content="64" />
        <meta name="twitter:image:alt" content="北京罕萌诊所" />
        <meta property="og:title" content="北京罕萌诊所" />
        <meta
          name="keywords"
          content="服务罕见病患者、诊断、治疗、康复、用药可及性"
        />
        <meta
          name="description"
          content="北京罕萌诊所践行“人民至上，生命至上”的宗旨，秉承医者初心的理想信念而成立经营。
          诊所致力于服务罕见病患者，将依托信息化平台和移动医疗技术，将集-在线诊疗、远程会诊、 线下医疗运营于一体，为罕见病患者提供预防、诊断、治疗、康复等一站式的健康维护和医疗服务，为罕见病患者提供先进的诊疗方案及精准的诊疗服务，创新解决患者用药可及性和获得性，缓解北京及周边区域罕见病诊疗压力，惠及更多的罕见病患者。
          诊所设施齐全，环境温馨，设有内科诊疗科目，并配备药房、治疗室、处置室等辅助科室。地址位于北京市顺义区金航西路4号院2号楼（天竺综合保税区）绿地自由港大厦B栋3层316-319室。"
        />
      </Head>
      <header className={styles.header}>
        <h1 className="visually_hidden" tabIndex={0}>北京罕萌诊所官方网站</h1>
        <div className={styles.header__logo}>
          <Image src={logo} alt="北京罕萌诊所" className={styles.logo} width={182} height={47} />
        </div>
        <div className={styles.header__banner}>
          <h2>人民至上  生命至上</h2>
          <Image src={banner} alt="Banner图" className={styles.banner} />
        </div>
      </header>
      <main>
        <section className={styles.wrapper}>
          <div className={styles.wrapper__inner}>
            <div className={styles.images_list}>
              <Image src={a1} alt="About Us图片" className={styles.about} width={340} height={213} />
              <Image src={a2} alt="About Us图片" className={styles.about1} width={248} height={213} />
              <Image src={a3} alt="About Us图片" className={styles.about1} width={248} height={213} />
              <Image src={a4} alt="About Us图片" className={styles.about} width={340} height={213} />
            </div>
            <h2>“人民至上，生命至上”的宗旨</h2>
            <p>
              诊所致力于服务罕见病患者，将依托信息化平台和移动医疗技术，将集诊疗、用药/特食、康复、保障于一体，为罕见病患者提供一站式服务。为罕见病患者提供先进的诊疗方案及精准的诊疗服务；汇集各专科/专病组会诊；发挥特区政策优势、保障罕见病药品可及；罕见病精准康复工具，线下和线上全程陪伴式服务；医保、慈善、救助等多元保障降负担。缓解北京及周边区域罕见病诊疗压力，惠及更多的罕见病患者。诊所设施齐全，环境温馨，设有内科诊疗科目，并配备药房、治疗室、处置室等辅助科室。
            </p>
          </div>
        </section>
        <section className={styles.white_wrapper}>
          <h2>诊所致力于服务罕见病患者<br />
            将依托信息化平台和移动医疗技术</h2>
          <div className={styles.images_list}>
            <div className={styles.images_item}>
              <Image src={l5} alt="诊疗 Practice" className={styles.image} width={227} height={182} />
              <h3>诊疗</h3>
              <p>Practice</p>
            </div>
            <div className={styles.images_item}>
              <Image src={l4} alt="用药 Medication" className={styles.image} width={227} height={182} />
              <h3>用药</h3>
              <p>Medication</p>
            </div>
            <div className={styles.images_item}>
              <Image src={l3} alt="特食 Special Food" className={styles.image} width={227} height={182} />
              <h3>特食</h3>
              <p>Special Food</p>
            </div>
            <div className={styles.images_item}>
              <Image src={l2} alt="康复 Rehabilitation" className={styles.image} width={227} height={182} />
              <h3>康复</h3>
              <p>Rehabilitation</p>
            </div>
            <div className={styles.images_item}>
              <Image src={l1} alt="保障 Guarantee" className={styles.image} width={227} height={182} />
              <h3>保障</h3>
              <p>Guarantee</p>
            </div>
          </div>
        </section>
        <section className={classNames(styles.wrapper, styles.strengths)}>
          <h2>我们的优势</h2>
          <p>Our strengths</p>
          <div className={styles.flexbox}>
            <div className={styles.flexbox_item}>
              <Image src={i1} alt="求医确诊周期长?" className={styles.image} />
              <h3>求医确诊周期长?</h3>
              <p>为罕见病患者提供先进的诊疗方案及精准的诊疗服务。</p>
            </div>
            <div className={styles.flexbox_item}>
              <Image src={i2} alt="疑难重症诊疗难?" className={styles.image} />
              <h3>疑难重症诊疗难?</h3>
              <p>汇集各专科/专病组会诊。</p>
            </div>
            <div className={styles.flexbox_item}>
              <Image src={i3} alt="无药难保不可选?" className={styles.image} />
              <h3>无药难保不可选?</h3>
              <p>发挥特区政策优势，保障罕见病药品可及。</p>
            </div>
            <div className={styles.flexbox_item}>
              <Image src={i4} alt="罕见病精准康复工具" className={styles.image} />
              <h3>罕见病精准康复工具</h3>
              <p>线下+线上全程陪伴式服务。</p>
            </div>
            <div className={styles.flexbox_item}>
              <Image src={i5} alt="多元保障降负担" className={styles.image} />
              <h3>多元保障降负担</h3>
              <p>医保、慈善、救助等多元保障降负担。</p>
            </div>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <div className={styles.footer__inner}>
          <div className={styles.footer_item}>
            <h3>工作时间</h3>
            <p>9:00-18:00（工作日）</p>
          </div>
          <div className={styles.footer_item}>
            <h3>诊所地址</h3>
            <p>北京市顺义区金航西路4号院2号楼（天竺综合保税区）绿地自由港大厦B栋3层316-319室</p>
          </div>
        </div>

        <div className={styles.footer_row}>
          <h3>友情链接</h3>
          <p className={styles.link_list}>
            <Link className={styles.link_item} href="http://www.nhc.gov.cn/" target="_blank">国家卫生健康委员会</Link>
            <Link className={styles.link_item} href="http://www.nhsa.gov.cn/" target="_blank">国家医疗保障局</Link>
            <Link className={styles.link_item} href="https://www.nmpa.gov.cn/" target="_blank">国家药品监督管理局</Link>
            <Link className={styles.link_item} href="https://wjw.beijing.gov.cn/" target="_blank">北京市卫生健康委员会</Link>
            <Link className={styles.link_item} href="https://ybj.beijing.gov.cn/" target="_blank">北京市医疗保障局</Link>
            <Link className={styles.link_item} href="https://yjj.beijing.gov.cn/" target="_blank">北京市药品监督管理局</Link>
            <Link className={styles.link_item} href="https://www.chard.org.cn/" target="_blank">中国罕见病联盟</Link>
          </p>
        </div>

        <div className={styles.footer_row}>
          <h3>北京罕萌诊所有限公司</h3>
          <p>
            <Link className={styles.link_item} href="https://beian.miit.gov.cn/" target="_blank">
              京ICP备2023036761号-1
            </Link>
          </p>
        </div>
      </footer>
    </React.Fragment>
  );
}